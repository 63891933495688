
.sign_page {
	background-image: url("../../Images/bg-svg-f\ \(1\).svg");
	background-repeat: no-repeat;
	background-size: cover;
	background-color: whitesmoke;
}
.signup_container {
	min-height: 65vh;
	align-items: center;
}
.p11 {
	text-align: center;
	font-size: 20px;
	font-weight: bolder;
	font-family: "Lato", sans-serif;
	color: #2d385e;
}
.texttag {
	margin-top: 15px;
}
.p22 {
	font-size: 15px;
	font-family: "Lato", sans-serif;
	text-align: center;
	color: #62626f;
}
.in11 {
	width: 100%;
	height: 50px;
	margin-bottom: 15px;
	border-radius: 5px;
	border: silver 1px solid;
	padding-left: 10px;
	font-size: 17px;
}

form {
	text-align: center;
}
.a11 {
	color: #ff8500;
	padding-left: 3px;
	font-size: 15px;
}
.p33 {
	text-align: center;
	color: #2d385e;
}
.a22 {
	padding: 0 3px;
	color: #ff8500;
	text-decoration: underline;
	font-size: 100%;
}
.btnSign {
	background-color: #516cf0;
	width: 100%;
	height: 52px;
	color: #ffffff;
	border-radius: 5px;
	border: 1.5px;
	font-size: 20px;
	margin-top: 28px;
	font-family: "Lato", sans-serif;
	text-align: center;
}

.btnSign:hover {
	background-color: #ff8500 !important;
}
.btnSign:active {
	border: 3px solid #516cf0;
}
.a33 {
	color: #ff8500 !important;
}
.form_wrapper {
	border-radius: 15px;
	background: #fcfbfb;
	box-shadow: 17px 17px 34px #d0d0d0;
}
.showHide {
	display: flex;
	justify-content: flex-end;
	margin: -52px 45px 0px 455px;
	opacity: 0.65;
	cursor: pointer;
}
.p33 {
	margin-bottom: 20px;
}
.a33:hover {
	text-decoration: underline;
}
.containe {
	width: 500px;
	margin: auto;
}
.SignupNote {
	margin-left: 100px;
	width: 600px;
}
.in11i {
	width: 475px;
}
.a22 {
	margin-right: 0px;
}